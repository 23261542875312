
.product-types {
    display: flex;
    flex-wrap: nowrap;
    column-gap: var(--m);
}

.product-type-header {
    font-weight: 700;
    font-size: var(--text-xl);
    color: white;
}

.product-type {
    display: flex;
    flex-direction: column;
    width: 367px;
    text-align: center;
    border-radius: var(--border-soft) var(--border-soft) 0 0 !important;
    margin-top: 28px;
    box-shadow: none !important;
    background: #D3D8E0 !important;
}

.product-type-selected {
    background: var(--background-secondary-color) !important;
}

.product-type-icon {
    position: relative;
    float: left;
    margin-top: 26px;
    margin-right: -26px;
    width: 35px;
}

.product-type-icon-flipped {
    transform: scaleY(-1);
}

.maturity-selected {
    border: 3px solid red;
}

.product-headers {
    display: grid;
    grid-template-columns: 75px 100px 130px 180px 130px 180px;
    gap: var(--m);
    width: 100%;
}

.product-row {
    display: grid;
    grid-template-columns: 75px 100px 130px 180px 130px 180px;
    gap: var(--m);
    width: 100%;
    height: 68px;
}

.product-headers-put {
    display: grid;
    grid-template-columns: 170px 100px 130px 180px 130px 180px;
    gap: var(--m);
    width: 100%;
}

.product-row-put {
    display: grid;
    grid-template-columns: 75px 75px 100px 130px 180px 130px 180px;
    gap: var(--m);
    width: 100%;
    height: 68px;
}


// product name
.product-column1 {
    grid-column: 1;
}

// est. yield
.product-column2 {
    grid-column: 2;
    text-align: center;
}

.estimated-yield-column {
    color: var(--carribean-green-color);
}

// strike price
.product-column3 {
    grid-column: 3;
}

.maturity-column {
    flex-direction: column;
}

// maturity
.product-column4 {
    grid-column: 4;
}

// deposit
.product-column5 {
    grid-column: 5;
    text-align: center;
}

//subscription end
.product-column6 {
    grid-column: 6;
}

//subscription end
.product-column7 {
    grid-column: 7;
}


.sp-item {
    width: 220px;
    height: 200px;
    padding: var(--m);
}

.sp-item-full-width {
    width: 460px;
    height: 155px;
    padding: var(--m);
}

.sp-item-disclaimer {
    width: 460px;
    height: 155px;
    padding: var(--m);
}

.disclaimer-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #FF3366;
}

.top-header-blank {
    background-color: #323135;
}

.top-header {
    background-color: #323135;
    background-image: url("../imgs/top-header-flowers.png");
    background-position: center center;
    background-size: contain;
    //background-repeat-x: repeat;
    height: 285px;
}

.header-deposit-currency {
    margin-top: 3px;
    margin-right: 6px;
    width: 29px;
}

.product-lifecycle-month {
    margin-left: 3px;
}

.product-lifecycle-icon {
    display: flex;
    flex-direction: row;
}

.product-lifecycle-icon-center {
    justify-content: center;
    align-items: center;
}

.product-lifecycle-icon-end {
    justify-content: end;
    align-items: end;
}

.product-lifecycle-icon img {
    max-width: var(--xl);
    max-height: var(--xl);
    padding: var(--s);
    border-radius: var(--border-soft);
    border: 3px solid #5C5FEB;
    background: white;
}



.sp-item-graphic {
    height: 50px;
}

.sp-deposit {
    width: 375px;
}

.product-info {
    min-width: 464px;
}

.product-info-wrapper {
    background: linear-gradient(91.6deg, #E8EDF6 0%, #F2F5F9 100%);
    border-radius: 8px;
    height: 100%;
}

.filters {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.filter-striking-price {
    width: 100px;
    border: none;
}

.filter-deposit-currency {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.filter-maturity-marker {
    width: var(--xl);
}

.filter-maturity-marker-text {
    color: #76808F;
}

.filter-maturity-marker-text-filled {
    color: var(--blue-main-color);
}

.grayscale span {
    color: #656262;
}

.grayscale img {
    filter: grayscale(100%);
}
// TODO: move these to a separate file?
.subscription-row {
    display: grid;
    grid-template-columns: 120px 120px 120px 100px 130px 130px 130px 130px;
    gap: var(--m);
    width: 100%;
}

.position-row-expired {
    display: grid;
    grid-template-columns: 150px 130px 150px 140px 150px 0px 110px 130px;
    gap: var(--m);
    width: 100%;
}

.position-row-current {
    display: grid;
    grid-template-columns: 150px 130px 150px 140px 190px 90px 170px 0px;
    gap: var(--m);
    width: 100%;
}

.product-lifecycle {
    display: grid;
    //grid-template-columns: 133px 133px 133px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;
    color: var(--text-color-normal);
}

.product-lifecycle > :nth-child(1) {
    grid-area: 1 / 1;
}

.product-lifecycle > :nth-child(2) {
    grid-area: 1 / 2;
    display: grid;
    //grid-template-columns: 133px 133px 133px;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    // width: 100%;
}

.product-lifecycle > :nth-child(3) {
    grid-area: 1 / 3;
}

.product-lifecycle > :nth-child(4) {
    grid-area: 2 / 1;
    display: flex;
    align-items: center;
}

.product-lifecycle > :nth-child(5) {
    grid-area: 2 / 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-lifecycle > :nth-child(6) {
    grid-area: 2 / 3;
    display: flex;
    align-items: center;
}

.product-lifecycle > :nth-child(7) {
    grid-area: 3 / 1;
}

.product-lifecycle > :nth-child(8) {
    grid-area: 3 / 2;
}

.product-lifecycle > :nth-child(9) {
    grid-area: 3 / 3;
}

.product-lifecycle .line {
    grid-area: 2 / 1 / 2 / 4;
    display: flex;
    align-items: center;
}

.product-lifecycle-line {
    //position: relative;
    //width: 595px;
    //top: -130px;
    height: 3px;
    background-color: #76808F;
    z-index: 0;
    width: 100%;
}

.spot-level {
    position: relative;
    z-index: 0;
}

.spot-level::before {
    content: '';
    position: absolute;
    top: 65%;
    width: 100%;
    height: 2px;
    background-color: green;
    z-index: -1;
}

.border-right {
    border-right: 0.5px solid #e2e2e2;
}

.border-left {
    border-left: 0.5px solid #e2e2e2;
    padding-left: var(--m);
}

.product-info-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.product-info-icon {
    display: flex;
    border: 1px solid #E8EDF6;
    border-radius: 50px;
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
    width: 48px;
    height: 48px;
}

.hidden {
    visibility: hidden;
}

.flex-product-timer {
    width: 255px;
}

.product-tooltip {
    width: 16px;
    padding-top: 4px;
}

.modal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
}

.modal {
    max-height: calc(100% - 10px);
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    margin: 10px 0 20px 0;
    padding: 20px 9px;
    overflow-x: hidden;
    vertical-align: middle;
    scrollbar-width: none;
}


.modal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}


.modal::-webkit-scrollbar {
    display: none;
    width: 12px;
    background-color: #F5F5F5;
}

.modal::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

.modal-close {
    all: initial;

    * {
        all: unset;
    }

    position: absolute;
    cursor: pointer;
    top: -18px;
    right: -30px;
}

.modal-close2 {
    all: initial;

    * {
        all: unset;
    }

    position: absolute;
    cursor: pointer;
    top: -18px;
    right: -18px;
}

.modal-card {
    box-shadow: var(--shadow-card);
    border-radius: var(--border-soft);
    background-color: var(--background-primary-color);
    width: 100%;
    min-width: 560px;
}

.modal-card-padding-s {
    padding: 0.8em 1.4em;
}

.modal-card-padding-m {
    padding: 0.9em 1.4em;
}

.modal-card-margin {
    margin-top: 6px;
}

.modal-row-margin {
    margin-top: 6px;
}

.modal-card-bkg {
    background-repeat: repeat;
    border-radius: var(--border-soft);
    background: linear-gradient(var(--background-primary-color), var(--background-primary-color));
    //background-image: url("../imgs/bkg-fruits.png");
    //background: linear_gradient(var(--background-primary-color),var(--background-primary-color));
}

.modal-header {
    font-size: var(--text-l);
    font-weight: 600;
    color: var(--text-color-normal);
}

.modal-subheader {
    font-size: var(--text-m);
    font-weight: 300;
    font-size: var(--text-s);
}

.products-modal {
    grid-area: main;
    color: var(--text-color-normal);
}

.products-detail-modal {
    grid-area: det;
}

.modal-apy {
    height: auto;
}

.modal-grid {
    display: grid;
    grid-template-columns: 55% 41%;
    grid-gap: 24px;
}

.modal-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-product-row-container {
    display: grid;
    grid-template-columns: 124px 124px 124px 124px;
    gap: var(--m);
    width: 100%;
    align-content: center;
    text-align: center;
}

.modal-detailed {
    display: grid;
    grid-template: 
        ". hide close" auto
        "main det det" 1fr / 600px 300px 300px;
    grid-gap: var(--m);
}

.modal-btn-hide {
    @extend %hide-btn;
    
    grid-area: hide;
}

.modal-btn-close {
    @extend %hide-btn;
    
    grid-area: close;
}

.modal-detailedview-hide {
    width: 238px;
}

%hide-btn {
    box-shadow: none !important;
}

.modal-detailedview-hide-button {
    box-shadow: none !important;
}

.modal-detailed-view-status {
    display: grid;
    justify-content: center;
    align-items: center;
    color: #1450F5;
}

.modal-detailed-view-status-bkg {
    background-image: url("../imgs/crab-3376653.png");
    height: 40px;
    width: 100%;
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;
}

.modal-past-performance {
    background: #f2f5f9a8;
    border-radius: 8px;
    width: 100%;
    height: 160px;
    text-align: center;
    padding-top: 50px;
}

.modal-chart {
    display: block;
    border-radius: 8px;
    width: 100%;
    height: 150px;
    background: linear-gradient(91.6deg, #f6eee8 0%, #f9f6f2 100%);
    padding-top: 3px;
}

.modal-simulate {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 150px 100px 0 auto;
    color: var(--text-color-normal);
}

.modal-simulate-option {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 100px 100px 0 auto;
    color: var(--text-color-normal);
}

.smart-contract-details {
    width: 100%;
    color:var(--text-color-normal);
}

.smart-contract-row {
    display: grid;
    grid-template-columns: 220px auto;
    width: 100%;
    gap: var(--m);
}

.modal-product-row {
    display: grid;
    grid-template-columns: 124px 124px 124px 124px;
    width: 100%;
}

.modal-product-content-row {
    display: grid;
    grid-template-columns: 124px 124px 124px 124px;
    width: 100%;
    height: 45px;
    align-content: center;
    margin-bottom: 4px;
}

.modal-product-maturity-header {
    grid-template-columns: 132px 132px 132px 132px;
    grid-column: 2/5;
    padding: 5px 0 4px 0;
    border-bottom: 1px solid #E2E2E2;
    margin-bottom: 10px;
    font-weight: 600;
}

.modal-product-row-risk-header {
    font-size: var(--text-s);
    font-weight: 600;
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 4px;
}

.modal-product-info {
    background: var(--background-primary-color);
    box-shadow: 0 1px 2px rgba(128, 138, 157, 0.12), 0 8px 32px rgba(128, 138, 157, 0.24);
    border-radius: 8px;
    height: 50px;
    align-content: center;
    display: grid;
    cursor: pointer;
}

.modal-product-info-selected {
    border: 1px solid #0160EE;
}

.modal-product-amount-row {
    display: grid;
    grid-template-columns: 55% 43%;
    width: 100%;
    align-content: center;
    gap: var(--l);
}


.modal-product-recap-row {
    display: grid;
    grid-template-columns: 40% 54%;
    width: 100%;
    gap: var(--m);
    font-weight: 300;
    font-size: var(--text-s)
}

.modal-product-recap-row-escrow {
    display: grid;
    grid-template-columns: 40% 50%;
    width: 100%;
    gap: var(--m);
    font-weight: 300;
    font-size: var(--text-s)
}

.modal-product-info-row {
    display: grid;
    grid-template-columns: 58% 42%;
    width: 100%;
    font-weight: 300;
    font-size: var(--text-s);
    align-items: center;
}

.modal-product-info-row-escrow {
    display: grid;
    grid-template-columns: 42% 58%;
    width: 100%;
    font-weight: 300;
    font-size: var(--text-s);
    align-items: center;
}


.modal-product-info-row-value {
    justify-content: end;
    min-width: 100px;
}

.modal-product-info-row-lg {
    display: grid;
    grid-template-columns: 38% 62%;
    width: 100%;
    font-weight: 300;
    font-size: var(--text-s);
    align-items: center;
}

.modal-product-info-row-recap {
    justify-content: end;
    min-width: 150px;
}

.modal-product-info-row-recap-escrow {
    justify-content: end;
    min-width: 132px;
}

.modal-product-info-progressbar {
    background: linear-gradient(91.6deg, #00CC88 0%, #03A972 100%);
    border-radius: 4px;
}

.modal-product-info-deposited-amount {
    background-color: green;
}

.modal-product-info-deposited-bar {
    background: linear-gradient(91.6deg, #00CC88 0%, #03A972 100%);
    border-radius: 4px;
    height: 100%;
    height: 8px;
}

.modal-product-info-deposited-bar-root {
    background: #E8EDF6;
    border-radius: 4px;
    height: 8px;
}

.modal-product-info-deposited-container {
    display: grid;
    grid-template-rows: 33% 33% 33%;
    grid-template-columns: 50% 50%;
    margin-top: 24px;
}

.modal-product-info-deposited-item1 {
    grid-column: 1;
}

.modal-product-info-deposited-item2 {
    grid-column: 2;
    justify-content: end;
}

.modal-product-info-deposited-pbar {
    grid-column: 1/3;
}

.modal-product-info-deposited-row {
    display: grid;
    grid-template-columns: 60% 40%;
    width: 100%;
    font-weight: 300;
    font-size: var(--text-s);
}

.modal-product-info-label {
    color: #828282;
}

.modal-simulate-spotlevel {
    color: #929AA5;
}

.text-purple {
    color: #8855FF;
}

.text-upside {
    color: #8855FF;
}

.text-downside {
    color: #FF3366;
}

.collapsible-panel {
    cursor: pointer;
}

.collapsible-panel-header {
    display: grid;
    grid-template-columns: auto 20px;
}

.collapsible-panel-header-button {
    color: var(--exo-primary);
    // color: var(--text-color-primary);
    transform: rotate(90deg);
    text-align: center;
    border-color: gray;
    border: 1px dotted;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    font-size: 20px;
    transition-timing-function: ease;
    transition-duration: .20s;
}

.collapsible-panel-header-button-open {
    transform: rotate(-90deg);
}

.list-header-upside {
    font-size: var(--text-l);
    font-weight: 500;
    color: #8855FF;
}

.list-header-downside {
    font-size: var(--text-l);
    font-weight: 500;
    color: #FF3366;
}

.list-header-put-worstoff {
    font-size: var(--text-l);
    font-weight: 500;
    color: #0941D7;
}

.list-header-subheading {
    width: 100%;
    color: var(--text-very-light);
}

.list-count-header {
    width: 100%;
    color: #76808F;
    border-bottom: 2px solid #E2E2E2;
}

.list-group-card {
    padding: 16px 24px;
    border: 1px solid #F2F5F9;
    background-image: url("../imgs/bkg-fruits.png");
    filter: drop-shadow(0px 1px 2px rgba(128, 138, 157, 0.12)) drop-shadow(0px 8px 32px rgba(128, 138, 157, 0.24));
    border-radius: 8px;
    width: 272px;
    height: 256px;
    align-items: center;
    text-align: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-position-x: center;
    transition: box-shadow .3s ease;
}

.list-group-card-dark {
    border: 1px solid #171717;
    background: linear-gradient(#2c2c2c, #222222), url("../imgs/bkg-fruits.png");
    filter: drop-shadow(0px 1px 2px rgba(128, 138, 157, 0.12)) drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.10));
}

.list-group-card:hover {
    box-shadow: 0px 0px 10px 3px #8080802e;
}

.list-group-card-header {
    padding: 0 8px;
    background: #FFFFFF;
    font-size: var(--text-l);
    font-weight: 400;
    border: 1px solid #F2F5F9;
    box-shadow: inset 2px 2px 4px #dad9d8;
    border-radius: 32px;
    width: 194px;
    height: 44px;
    text-align: center;
    display: grid;
    align-self: center;
    align-items: center;
    grid-template-columns: 36px auto;
    justify-content: center;
}

.list-group-card-header-dark {
    border: 1px solid #242222;
    box-shadow: inset 2px 2px 4px #292929;
    background: #292929;
    color: #8b8b8b;
}

.list-group-card-header-multi {
    justify-content: space-around;
}

.list-group-card-subheader {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
}

.list-group-card-line {
    width: 100%;
    border-bottom: 0.5px solid #cfcfcf;
}

.list-group-card-apy {
    font-weight: 500;
    font-size: var(--text-l);
    color: #03A972;
}

.list-group-card-apy-subheader {
    color: #828282;
    font-size: var(--text-xs);
    font-weight: 400;
}

.deposits-row {
    display: grid;
    grid-template-columns: 40% 13% 47%;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.deposits-row-without-icon {
    display: grid;
    grid-template-columns: 40% 60%;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.deposits-label {
    padding: 3px 3px;
    background-color: var(--exotic-green);
    color: white;
    text-align: center;
    width: 100%;
    border-radius: 6px;
    font-size: var(--text-xs);
}

.provisionalpos-label {
    padding: 3px 3px;
    background-color: #e93580;
    color: white;
    text-align: center;
    width: 100%;
    border-radius: 6px;
    font-size: var(--text-xs);
}

.products-list {
    justify-content: left;
}

@media screen and (max-width: 640px) {

    .product-headers {
        display: grid;
        grid-template-columns: 140px 130px 150px;
        gap: var(--m);
        width: 100%;
    }

    .product-row {
        display: grid;
        grid-template-columns: 140px 130px 150px;
        gap: var(--m);
        width: 100%;
        height: 68px;
    }

    .product-headers-put {
        display: grid;
        grid-template-columns: 140px 130px 150px;
        gap: var(--m);
        width: 100%;
    }

    .product-row-put {
        display: grid;
        grid-template-columns: 140px 130px 150px;
        gap: var(--m);
        width: 100%;
        height: 68px;
    }

    .products-list {
        justify-content: center;
    }

    .list-group-card {
        width: 100%;
        max-width: 368px;
    }
}
