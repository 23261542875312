:root {
    --time-to-expiry-width: 175px;
    --auction-border-radius: 25px
}

.auction {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: stretch;
    background-color: var(--background-primary-color);
    border-radius: var(--border-sharp);
    margin-top: 20px;
    box-shadow: var(--shadow-card);
}

.auction-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: var(--s);
    //background-color: var(--background-secondary-color);
    border-radius: var(--auction-border-radius);
    text-align: center;
}

.auction-headers {
    display: flex;
    width: calc(100% - 118px - 40px);
    margin-top: 40px;
    padding: 0 20px;
}

.auction-headers > :first-child {
    flex: 2;
}

.auction-headers-liquitiy {
    display: flex;
    width: calc(100% - 118px - 40px);
    margin-top: 40px;
    padding: 0 20px;
}

.auction-headers-liquitiy > :nth-child(2) {
    flex: 2;
}

.auction-header {
    flex: 1;

    font-size: 12px;
    color: var(--exotic-gray);
    padding: 0 15px;
}

.auction-content > * {
    // flex-grow: 1;
    padding: 15px;
    flex: 1;
}

.auction-content > *:not(:first-child) {
    border-left: 0.5px solid #BDBDBD;
}

.auction-product {
    font-weight: 700;
}

.auction-product-types {
    display: flex;
    flex-direction: column;
}

.auction-position-size {
    font-weight: 500;
}

.auction-position-size .currency {
    font-weight: 700;
    margin-left: 7px;
}

.auction-maturity {
    font-weight: 500;
}

.auction-projected-yield {
    font-weight: 500;
    color: var(--low-color);
}



.auction-time-to-expiry {
    font-size: 18px;
    font-weight: 500;
    width: var(--time-to-expiry-width);
}

.auction-direction {
    font-size: 14px;
}

.auction-price {
    display: flex;
    flex-direction: column;

}

.auction-price-currency {
    font-size: 12px;
    color: var(--text-light);
    margin-left: 7px;

}

.auction-price-kind {
    font-size: 12px;
    color: var(--text-light);
    margin-top: 10px;

}

.time-to-expiry-header {
    min-width: var(--time-to-expiry-width);
}