:root {
    --stat-card-border-radius: 20px;
}


.stat-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.stat-card {
    flex-grow: 1;
    background-color: var(--background-primary-color);
    padding: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: var(--stat-card-border-radius);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: var(--xxl);
    width: calc(var(--xxl) * 2);
    color: var(--text-color-normal);
}

.stat-card-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: var(--border-soft);
}

.stat-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 16px;
}

.stat-card-title {
    font-size: 1.5em;
    font-weight: 700;
}

.stat-card-subtitle {
    font-size: .8em;
    margin-top: 4px;
}

.stat-card-button {
   // margin-top: 8px;
   height: 64px;
    width: 84px;
}

.stat-card-logout {
    --hue: 357
}

.stat-card-retake {
    --hue: 0;
    --saturation: 0%;
}

.stat-card-background-1 {
    background: rgba(72, 247, 140, 0.25);
}

.stat-card-background-2 {
    background: rgba(30, 87, 182, 0.25);
}

.stat-card-background-3 {
    background: rgba(179, 127, 89, 0.25);
}

.stat-card-background-4 {
    background: rgba(97, 96, 247, 0.25);
}

.stat-card-background-5 {
    background: rgba(247, 214, 59, 0.25);
}

.stat-card-background-6 {
    background: rgba(247, 57, 47, 0.25);
}

.stat-text-positive {
    color: var(--carribean-green-color)
}

.stat-button {
    width: auto !important; //TODO: better way to enforce this rule?
}

.stat-no-data {
    width: 368px;
}

.stat-no-data-title {
    font-size: var(--text-m);
    font-weight: 500;
}

.stat-no-data-message {
    font-size: var(--text-s);
    font-weight: 300;
}

.stat-swap-card {
    width: 450px;
}

.stat-swap-button {
    width: 87px; //TODO: better way to enforce this rule?
}