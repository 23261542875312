
.card {
    flex-shrink: 1;
    flex-basis: 388px;
    border-radius: 25px;
    background-color: white;
    background: url("../imgs/background-fruits.png");
    padding: var(--l) var(--m);
    box-shadow: var(--shadow-card);
}

.card-detail {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: 48px;
  border-radius: 25px;
  background-color: white;
  min-height: 1000px;
  box-shadow: var(--shadow-card);
}

// Card animation

.card-enter{
  opacity: 1;
}

.card-enter-active {
  opacity: 0;
  transition: opacity 300ms;
}

.card-exit {
  opacity: 1;
}

.card-exit-active {
  opacity: 0;
  transition: opacity 300ms;
} 


// Card detail animation

.card-detail-enter{
  opacity: 0;
  transform: scale(0.5);
}

.card-detail-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.card-detail-exit {
  opacity: 1;
  transform: scale(1.0);
}

.card-detail-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 300ms, transform 300ms;
} 

.currency-header {
  text-align: center;
}

.card-detail .currency-header {
  text-align: left;
}

.card-detail .text {
  text-align: left;
  margin: 0px;
  font-size: 18px;
  line-height: 32px;
}

@media screen and (max-width: 600px) {
  .card {
      width: 344px;
  }
}

.text {
  text-align: center;
  margin-top: 4px;
  margin-left: 24px;
  margin-right: 24px;
  font-size: 12px;
  line-height: 16px;
  min-height: 80px;
}

.card-detail .text {
  margin-top: 8px;

}

.detail-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 0.5px solid #e2e2e2;
}

.strategy {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: auto auto;
  gap: var(--l) 0px;
  margin-top: var(--l);
}

.strategy > * {
    flex: 1;
    flex-basis: 50%;
}

.strategy-detail {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
}

.center {
  text-align: center;
}

.strategy-header {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
}

.strategy-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--exotic-gray);
  text-align: center;
}



.start-earning-yield {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.start-earning-yield .start-earning-yield-button {
  margin-top: 30px;
  width: 100%;
  --hue: 156;
  --saturation: 71%;
  --lightness: 56%;
  background: linear-gradient(180deg, hsl(156,71%,56%) 0%, hsl(156,64%,54%) 100%);
  box-shadow: 0px 2px 4px rgba(15, 218, 137, 0.3);
  border-radius: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  text-transform: none;
  height: 50px;
}

.start-earning-yield-button-icon {
    margin-left: 10px;
}

.back-to-products {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-transform: none;
  text-decoration-line: underline;
  color: #899A96;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.info {
    max-width: 560px;
}

.illustration {
    width: 100%;
    height: 464px;
    border-radius: 25px;
    background: var(--background-primary-color);
    padding: 20px
}

.tab-indicator {
  background-color: var(--exotic-blue);
}

.deposits-container {
    display: inline-block;
}

.deposits {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: var(--s) var(--m);
    border-radius: 32px;
    border: 1px solid #F2F5F9;
    box-shadow: 2px 2px 4px 0px #DAD9D8 inset, -8px -8px 16px 0px #FFFFFF inset;
}

/*.deposits-btc {
    color: var(--mint-color-btc);
    border-color: var(--mint-color-btc);
}

.deposits-usdc {
    color: var(--mint-color-usdc);
    border-color: var(--mint-color-usdc);
}

.deposits-eth {
    color: var(--mint-color-eth);
    border-color: var(--mint-color-eth);
}*/

.deposits img {
    margin-left: 7px;
}

.product-name {
    font-size: calc(var(--text-m) + 4px);
    color: var(--text-dark);
    font-weight: 500;
    margin: var(--l) 0 var(--m) 0;
    text-align: center;
}

.card-detail .product-name {
    text-align: left;
    font-size: 32px;
}

.card-detail .info-text {
    text-align: left;
    font-size: 14px;
}

.info-text {
    font-size: 12px;
    color: var(--text-light);
    display: flex;
    padding-bottom: 20px;
}

.info-text svg {
    font-size: 18px;
    margin-right: 10px;
}

/* Override styles for card detail */@at-root

.card-detail .strategy-detail {
    margin-top: 10px;
}

// TODO : This is kind of a hacky way to influence a child component styling. Might be better to just have two specific components.
.card-detail .strategy-detail * {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
}

.card-detail .strategy-detail .strategy-header {
    font-size: 24px;
}

.card-detail .strategy-detail .strategy-text {
    color: #000;
}

.min-max-currency {
    font-size: 20px;
    color: var(--exotic-gray);
    padding-left: 2px;
}

.min-max-separator {
    font-size: 20px;
    color: var(--exotic-gray);
    padding-right: 12px;
    padding-left: 12px;
}

.card-detail .strategy-detail .subscription-time, .subscription-time {
    font-size: 18px;
    width: 170px;
    display: inline-block;
}

.chart-x-texts {
    display: flex;
    justify-content: space-between;
}

.market-size {
    width: 100%;
}

.market-size-bar {
    border-radius: 12px;
    height: 10px;
    margin: 5px 0;
}