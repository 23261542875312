
.content {
    text-align: left;
    padding-top: var(--xl);
    color: white;
}

.footer {
    background: black;
}

.copyright {
    width: 100%;
    text-align: center;
    margin-top: 60px;
    padding: {
        top: 30px;
        bottom: 30px;
    }
    font-size: 16px;
    color: #76808F;    
    border-top: 1px solid #E8EDF6;
}

.footer-title {
    color: #FEE799;
    font-size: var(--text-xxl);
    font-weight: 700;
}

.title {
    color: var(--corat);
    font-size: 20px;
}

.logo-tagline {
    max-width: 270px;
    font-size: 16px;
}

.logo {
    width: 180px;
    height: 48px;
}

.icon {
    color: #00459D;    
}

.item a {
    color: white;
    text-decoration: none;
}

.item a:hover {
    color: var(--exotic-blue);
}