.level {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.risk-icon {
    display: flex;
    border: 1px solid #E8EDF6;
    border-radius: 50px;
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.level img {
    /*width: 50px;*/
    padding: var(--s);
}

.scale {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //width: 100%;
}

.scale > * {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gauge {
  width: 100%;
  height: 13px;
  margin-top: 10px;
  background: linear-gradient(90deg, #25C685 0%, #FFBC25 49.48%, #FF464F 100%);
  border-radius: 12px;
}

.marks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
//   width: 99%;
  padding: 0 5px;
  height: 100%;
  align-items: center;
}

.mark {
  width: 5px;
  height: 5px;
//   margin-top: 5px;
  border-radius: 50%;
  background-color: #4F4F4F;
}

.marker {
  position: absolute;
  margin-left: 5px;
  margin-top: 10px;
}

.title {
  font-size: 24px;
}

.block {
  width: 368px;
  height: 80px;
  border: 1px solid #BDBDBD;
  border-radius: 25px;
  color: #BDBDBD;
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;
}

.block-icon {
  filter: invert(76%) sepia(17%) saturate(0%) hue-rotate(160deg) brightness(98%) contrast(90%);
}

.market-view-outer {
    height: 20px;
    background-color: #c9c9c980;
    border-radius: 10px;
    flex-direction: column;
    padding: 0px;
}

.market-view-inner {
    width: 100%;
    height: calc(100%);
    background-color: #c9c9c9;
    border-radius: 10px;
    flex-direction: column;
    position: relative;
    background: linear-gradient(90deg, #e13603 0%, #f4f85a 46%, #0ecf3e 100%);
    //border: 2px solid #50c7c7;
}

.market-view-inner-border {
    width: 100%;
    height: 100%;
    background-color: #6ec3c3;
    border-radius: 10px;
    flex-direction: column;
    position: relative;
    padding: 2px;
}

.market-view-level {
    height: 18px;
    border-radius: 10px;
    background: rgb(251,154,63);
    //background: linear-gradient(90deg, rgba(251,154,63,1) 0%, rgba(244,248,90,1) 46%, rgba(70,252,115,1) 100%);
    border: 2px solid #50c7c7;
    position: absolute;
}

/*.market-view-red-to-green {
    background: linear-gradient(90deg, rgba(251,154,63,1) 0%, rgba(244,248,90,1) 46%, rgba(70,252,115,1) 100%);
}

.market-view-red-to-yellow {
    background: linear-gradient(90deg, rgba(251,154,63,1) 0%, rgba(244,248,90,1) 46%, #f4f659 100%);
}

.market-view-yellow-to-green {
    background: linear-gradient(90deg, rgba(251,154,63,1) 0%, rgba(244,248,90,1) 46%, rgba(70,252,115,1) 100%);
}
*/

.market-view-red-to-light-red {
    background: linear-gradient(90deg, #e13603 0%, #f17548 46%, #fd9a7c 100%);
}

.market-view-red-to-yellow {
    background: linear-gradient(90deg, #e13603 0%, #f4f85a 46%, #f4f85a 100%);
}

.market-view-red-to-light-green {
    background: linear-gradient(90deg, #e13603 0%, #f4f85a 75%, #51fc7c 100%);
}

.market-view-red-to-green {
    background: linear-gradient(90deg, #e13603 0%, #f4f85a 46%, #0ecf3e 100%);
}

.market-view-light-red-to-yellow {
    background: linear-gradient(90deg, #f17548 0%, #f4f85a 46%, #f3f85a 100%);
}

.market-view-light-red-to-light-green {
    background: linear-gradient(90deg, #f17548 0%, #f4f85a 46%, #51fc7c 100%);
}

.market-view-light-red-to-green {
    background: linear-gradient(90deg, #f17548 0%, #f4f85a 25%, #0ecf3e 100%);
}

.market-view-yellow-to-light-green {
    background: linear-gradient(90deg, #f4f85a 0%, #f4f85a 46%, #51fc7c 100%);
}

.market-view-yellow-to-green {
    background: linear-gradient(90deg, #f4f85a 0%, #f4f85a 46%, #0ecf3e 100%);
}

.market-view-light-green-to-green {
    background: linear-gradient(90deg, #51fc7c 0%, #51fc7c 46%, #0ecf3e 100%);
}

.market-view-legend-row {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    margin-top: 4px;
}

.market-view-legend-col1 {
    grid-column: 1;
}

.market-view-legend-col2 {
    grid-column: 2;
}