.yield {
  width: 100%;
  max-width: 464px;
//   height: 436px;
//   background: #FFFFFF;
  border-radius: 25px;
//   border: 1px solid black;
}

.yield-content {
    margin: 20px;
}

.tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tab {
  width: 50%;
  height: 64px;
  color: var(--background-primary-color);
  --hue: 100;
  --saturation: 0%;
  --lightness: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-left {
  border-top-left-radius: 25px;
}

.tab-right {
  border-top-right-radius: 25px;
}

.tab-selected {
  color: #000000;
  background: var(--exotic-primary-color);
}

.mode {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  margin-top: 12px;
  color: #899A96;
  cursor: pointer;
}

.mode-selected {
  color: var(--exotic-blue);
}

.amount-label {
  display: block;
  color: #232735;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-top: 39px;
  margin-bottom: 9px;
}

.balance-label {
  display: block;
  color: #828282;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: 40px;
}

.max {
  color: var(--exo-primary);
  text-decoration-line: underline;
  cursor: pointer;
}

.amount-input {
  //border: 1px solid #1A3B34;
  border-radius: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  //width: 100%;
  //height: 58px;
  //margin-top: 8px;
}

.amount-input input[type=text] {
    width: 100%;
    padding-left: 16px;
    margin-left: 16px;    
}

.yield-text {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 16px;
    color: var(--text-color-normal);
}


.yield-input {
    width: 100%;    
}

.slider-numbers {
    width: calc(100% - 30px);
    margin-left: 15px;
    position: relative;
    height: 20px;
}

.limit-yield-subtext {
    display: flex;
    justify-content: space-between;
    margin-top: -7px;

    font-size: 7px;
    color: var(--text-light);
}

.currency-input {
    color: var(--text-color-normal);
}

.limit-yield-subtext:first-child {
    text-align: left;
}

.limit-yield-subtext:last-child {
    text-align: right;
}

.invert {
    flex-direction: row-reverse;
}

.warning {
    margin-bottom: 20px;
}