.page {
    background-color: var(--background-primary-color);
    overflow: hidden;
}

.section-bkg-plain {
    background: var(--landingpage-alternate-bkg);
}

.splash-content {
    // max-width: 561px;
    margin-top: 170px;
}

.splash-section {
    background: var(--landingpage-section-bkg);
    top: 0px;
}

.tryassistant-section {
    padding: 15px 0px 80px 0px;
}

.try-exotic-assistant-descr {
    font-size: 20px;
    font-weight: 400;
    color: var(--text-color-normal);
}

.landing-bkg-section {
    background: var(--landingpage-section-bkg);
    padding: 15px 0px 80px 0px;
}

.splash-title {
    color: var(--text-color-primary);
    font-weight: 700;
    font-size: 54px;
    line-height: 64px;
    color: var(--text-color-normal);
}

.splash-subtitle {
    font-weight: 400;
    font-size: 20px;
    max-width: 600px;
    color: var(--text-color-normal);
}

.diagram {
    background-image: url("../imgs/diagram2.png");
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 345px;
    width: calc(100vw - 40px);
}

.section-backedby {
    display: flex;
    flex-direction: row;
    justify-content: start;
}

@media screen and (max-width: 600px) {
    .diagram {
        background-image: url("../imgs/diagram_mobile.png");
    }
}


.diversify-portfolio {
    padding-top: var(--xl);
    padding-bottom: var(--xl);
    background-image: url("../imgs/diversify-bg.png");
    background-position: left bottom;
}

.cards-images {
    justify-content: center;
    margin: 0 -20px;
}

.cards-images > * {
    width: 30%;
    min-width: 150px;
    margin-top: 8%;
}

.cards-images :nth-child(1) {
    z-index: 1;
}

.cards-images :nth-child(2) {
    z-index: 100;
    /*margin: 0 -11vw;*/
    margin: 0 -15%;
}

.cards-images :nth-child(3) {
    z-index: 1;
}

@media screen and (max-width: 600px) {
    .cards-images :nth-child(2) {
        z-index: 100;
        /*margin: 0 -11vw;*/
        /*margin: 0 -25%;*/
    }

    .diversify-portfolio {
        background-position: left 150px;
    }
}

.assistant {
    display: flex;
    box-shadow: 0px 1px 2px rgba(128, 138, 157, 0.12), 0px 8px 32px rgba(128, 138, 157, 0.24);
    border-radius: 15px;
    max-width: 464px;
}

.assistant-image {
    position: relative;
    top: 0px;
    left: 0px;
    width: 240px;
}

.accordian-container {
    padding: 20px 20px 10px 20px;
    color: var(--text-color-normal);
}

.accordian-container-open {
    background: var(--landingpage-alternate-bkg);
}

.accordian-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: var(--text-l);
}

.accordian-divider {
    margin-bottom: 10px;
}

.accordian-icon {
    // float: right;
    cursor: pointer;
}

.faq-background {
    background-image: url("../imgs/black-footer-top.svg");
    background-repeat: no-repeat;
    background-position: center;
    height: 224px;
    //background-color: var(--background-secondary-color);
}

/* @media screen and (max-width: 375px) {
    .faq-background {
        background-image: url("../imgs/black-footer-top_mobile.svg");
    }    
} */


.community-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    flex-basis: 1;
}

.community-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 16px;
    color: #FFF;
    text-decoration: none
}

.community-wrap {
    border-right: 1px solid;
    display: grid;
    grid-template-columns: 48px auto;
    align-items: center;
    column-gap: 20px;
    padding: 0 30px 0 0;
}

.community-icon-lang {
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    border-radius: 16px;
    color: #FFF;
    text-decoration: none;
}


.twitter-icon {
    background-image: url("../imgs/twitter.png");
}

.discord-icon {
    background-image: url("../imgs/discord.png");
}

.telegram-icon {
    background-image: url("../imgs/telegram.png");
}

.medium-icon {
    background-image: url("../imgs/medium.png");
}

.monogram-icon {
    background: #12100E;
}

.gitbook-icon {
    background: #FFFFFF;
    border: 1px solid #4285FD;
}

.fruits {
    background-image: url("../imgs/exotic-splash.png");
    background-repeat: no-repeat;
    background-position-x: right;
    height: 850px;
    width: 850px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 0;
}

.products-page-demo {
    background-image: url("../imgs/products-page-demo.png");
    background-repeat: no-repeat;
    background-position-x: right;
    height: 450px;
    width: 768px;
    position: absolute;
    right: 0;
    top: 170px;
}

@media screen and (max-width: 600px) {
    .fruits {
        top: -225px;
        background-size: contain;
    }

    .splash-content {
        margin-top: var(--xl);
        margin-bottom: var(--l);
    }

    .products-page-demo {
        display:none;
    }
}


.faq-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-rows: auto auto 1fr 1fr;
}

.content-width {
    max-width: 950px;
}

@media screen and (max-width: 600px) {
    .content-width {
        margin-left: var(--m);
        margin-right: var(--m);
    }
}

.diagram-button-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: var(--xl);
    grid-row-gap: var(--s);
    flex-basis: 1;
    flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
    .diagram-button-row {
        flex-direction: column;
    }
}

.backer {
    text-decoration: none;
    z-index: 1;
    // flex-basis: 100%;
}

.back-by-row {
    display: grid;
    grid-template-areas: "a a a a a a a a a a a a a a a";
    gap: var(--l);
    grid-auto-columns: 140px;
    height: auto;
}

@media screen and (max-width: 600px) {
    .back-by-row {
        flex-direction: column;
        gap: var(--l);
    }
}

.questions {
    margin-left: var(--xxl);
    margin-right: var(--xxl);
}

@media screen and (max-width: 600px) {
    .questions {
        margin-left: 0;
        margin-right: 0;
    }
}

.community-text {
    margin-left: var(--xxl);
    margin-right: var(--xxl);
    font-size: 16px;
    font-weight: 400;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-top: 24px;
    background-color: #FFF;
    width: 560px;
}

.modal-lang {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    z-index: 9999;
    background-color: white;
    padding: 40px;
    min-width: 70%;
    background-color: var(--background-primary-color);
    color: var(--text-color-normal);
}

.modal-lang:focus-visible {
    outline: none;
}

@media screen and (max-width: 600px) {
    .modal {
        max-width: 344px;
    }
}

.modal-close {
    all: initial;

    * {
        all: unset;
    }

    position: absolute;
    margin-right: 0px;
    margin-bottom: 0px;
    left: 510px;
    top: -32px;
    cursor: pointer;
}

.mail-list {
    display: block;
}

@media screen and (max-width: 600px) {
    .modal-close {
        left: 290px;
    }
}

.press-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--m);
    flex-basis: 490px;
}

.press-grid {
    display: grid;
    grid-template-columns: 90px 200px auto;
    grid-template-rows: 50px 50px auto;
    max-width: 480px;
    // grid-auto-rows: 50px;
    // background-color: #eaeaea
}

.press-grid:nth-child(2) {
    display: grid;
    grid-template-columns: auto 200px 90px;
    grid-template-rows: 50px 50px auto;
    margin-top: calc(var(--xl) + var(--l)) ;
}

.press-logo {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-primary-color);
    border-radius: var(--border-soft);
    box-shadow: 0px 8px 32px 0px #808A9D3D, 0px 1px 2px 0px #808A9D1F, 0px 0px 40px 32px #808A9D0D;
}

.press-quote-content {
    font-size: 20px;
    font-weight: 400;
    line-height: 28.8px;
    color: var(--text-color-light);
    background-color: var(--background-black);
    border-radius: var(--border-soft);
    padding: var(--xl) var(--l) var(--l) var(--l);
    height: 390px;
    width: 367px;
}

.press-author {
    font-weight: 500;
}

.press-author-role {
    font-weight: 400;
}

.press-read-more {
    color: #5B86E5;
    margin-top: var(--s);
}

.press-read-more a {
    font-size: 20px;
    font-weight: 400;
    color: #5B86E5;
}

.press-read-more a:hover {
    color: #5B86E5;
}


.press-grid:nth-child(1) {
    .press-logo {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .press-quote {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 4;
    }

    .press-read-more {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 4;
        grid-row-end: 5;
    }

    .press-author-role {
        color: #00CC88;
    }
}

.press-grid:nth-child(2) {
    .press-logo {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .press-quote {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 4;
    }

    .press-read-more {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 4;
        grid-row-end: 5;
    }

    .press-author-role {
        color: #FFCC00;
    }
}


.roadmap-timeline-top {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 65px;
    z-index: 0;
    height: 2px;
    background: linear-gradient(274.15deg, #0160EE 0%, #6EB8C8 49.31%, #6B6DEF 100%);
}

.roadmap-timeline-right {
    position: absolute;
    width: 2px;
    right: 0px;
    top: 65px;
    z-index: 0;
    height: 158px;
    border: 1px dashed #61adcd;
}

.roadmap-timeline-middle {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 225px;
    z-index: 0;
    height: 2px;
    border: 1px dashed #61adcd
}

.roadmap-timeline-left {
    position: absolute;
    width: 2px;
    left: 0px;
    top: 225px;
    z-index: 0;
    height: 150px;
    border: 1px dashed #61adcd;
}

.roadmap-timeline-bottom {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 375px;
    z-index: 0;
    height: 2px;
    background: linear-gradient(274.15deg, #0160EE 0%, #6EB8C8 49.31%, #6B6DEF 100%);
}

.roadmap-timeline-dot {
    position: absolute;
    height: 6px;
    width: 6px;
    background: #6C89E0;
    border-radius: 50%;
    top: 55px;
}

.roadmap-timeline-image {
    width: 100%;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
}

.roadmap-timeline-lead {
    position: relative;
    width: 0px;
    top: 40px;
    font-weight: 600;
    left: -50px;
}

.roadmap-timeline-lead-bottom {
    margin-left: 0px;
}

.roadmap-timeline-image-1 {
    background-image: url("../imgs/timeline-1.png");
}

.roadmap-timeline-image-2 {
    background-image: url("../imgs/timeline-2.png");
}

.roadmap-timeline-image-3 {
    background-image: url("../imgs/timeline-3.png");
}

.roadmap-timeline-image-4 {
    background-image: url("../imgs/timeline-4.png");
}

.roadmap-timeline-image-5 {
    background-image: url("../imgs/timeline-5.png");
}

.roadmap-timeline-image-6 {
    background-image: url("../imgs/timeline-6.png");
}

.roadmap-timeline-ticked {
    background-image: url("../imgs/timeline-ticked.png");
    height: 17px;
    width: 17px;
    background-repeat: no-repeat;
    background-position: center;
}

.roadmap-timeline-unticked {
    background-image: url("../imgs/timeline-unticked.png");
    height: 17px;
    width: 17px;
    background-repeat: no-repeat;
    background-position: center;
}

.roadmap-timeline-descr-row {
    display: grid;
    grid-template-columns: 25px auto;
    margin-bottom: 10px;
    font-weight: 400;
}

.roadmap-timeline-descr-box {
    position: absolute;
    top: 80px;
}

.roadmap-timeline-item {
    top: 32px;
    justify-content: center;
    display: flex;
    position: relative;
    height: 200px;
    width: 265px;
    top: 8px;
}

.roadmap-timeline-item-header {
    justify-content: center;
    display: flex;
    color: #6C89E0;
    position: absolute;
    top: -22px;
    width: 100%;
    font-weight: 500;
}

.roadmap-row {
    display: flex;
    justify-content: space-around;
}

.roadmap-row-2 {
    display: flex;
    justify-content: space-around;
    top: 110px;
    position: relative;
}

.roadmap-row-3 {
    display: flex;
    justify-content: center;
    top: 110px;
    position: relative;
    font-size: 20px;
}


.tab-indicator {
    // background-color: #FB8F67;
    background-color: transparent;
    display: flex;
    justify-content: center;
    top: 45px;
}

.tab-indicator::before {
    content: '';
    background: linear-gradient(91.6deg, #1450F5 0%, #3B6DF7 100%);
    // height: 100%;
    width: 100%;
}

.tab-panel {
    padding: 0;
    min-height: 300px;
}

.tab-link {
    text-decoration-line: none;
    text-transform: none;
    color: #7D7C84;
    margin: 16px 0;
    font-size: var(--text-m);
    font-weight: 400;
    line-height: 100%;
}

:global(.Mui-selected) .tab-link {
    font-weight: 600;
    color: var(--exotic-blue);
}

.press-and-resources-tab-context {
    justify-content: center;
    display: grid;
}

.roadmap-graph {
    position: relative;
    color: var(--text-color-normal);
}

.resources-container {
    position: relative;
}

@media screen and (max-width: 600px) {
    .press-items {
        gap: var(--xl);
    }

    .press-grid:nth-child(1) {
        .press-quote {
            grid-column-start: 1;
            grid-column-end: 4;
        }

        .press-read-more {
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }

    .press-grid:nth-child(2) {
        .press-quote {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 4;
        }

        .press-read-more {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 4;
            grid-row-end: 5;
        }
    }


    .roadmap-timeline-top {
        position: absolute;
        width: 100%;
        left: 0px;
        top: 65px;
        z-index: 0;
        height: 2px;
        background: linear-gradient(274.15deg, #0160EE 0%, #6EB8C8 49.31%, #6B6DEF 100%);
    }

    .roadmap-timeline-right {
        top: 65px;
        height: 215px;
    }

    .roadmap-timeline-middle {
        top: 280px;
    }

    .roadmap-timeline-left {
        left: 0px;
        top: 280px;
        height: 158px;
    }

    .roadmap-timeline-bottom {
        left: 0px;
        top: 437px;
    }

    .roadmap-timeline-item {
        height: 262px;
    }

    .roadmap-timeline-lead {
        left: 0px;
    }

    .resources-container {
        justify-content: center;
        grid-gap: 40px;
    }

    .roadmap-row-3 {
        top: 120px;
    }

    .roadmap-timeline-lead {
        font-size: 12px;
        top: 50px;
    }
}

.roadmap-timeline {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 65px;
    z-index: -1;
}


.roadmap-timeline-vertical {
    display: none;
}

@media screen and (max-width: 1200px) {
    .roadmap-timeline {
        display: none;
    }

    .roadmap-timeline-vertical {
        display: block;
        position: absolute;
        z-index: -1;
        height: calc(100% + 35px);
        width: 100%;
    }
    // This whole layout would be better of with css-grid but this works for now
    .roadmap-timeline-header {
        position: absolute;
        margin-top: 30px;
    }
}

.roadmap-timeline-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #FF3366;
    font-size: 18px;
    font-weight: 500;
}

.roadmap-timeline-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.roadmap-timeline-icon img {
    max-width: var(--xl);
    max-height: var(--xl);
    padding: var(--s);
    border-radius: var(--border-soft);
    border: 3px solid #5C5FEB;
    background: white;
}

.roadmap-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .roadmap-container {
        flex-direction: column;
    }
}

.roadmap-box {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    border-radius: var(--border-sharp);
    width: 366px;
    height: 174px;
    column-gap: var(--m);
    color: var(--text-color-light);
}

.roadmap-list {
    line-height: var(--l);
    width: 100%;
}

.roadmap-1 {
    background: linear-gradient(90.02deg, #0160EE 0.01%, #00317A 310.04%);
}

.roadmap-2 {
    background: linear-gradient(90deg, #0160EE -104.21%, #00317A 204.07%);
}

.roadmap-3 {
    background: linear-gradient(90deg, #5D5FEF -206.76%, #0160EE -206.73%, #00317A 100%);
}

.read-more {
    display: flex;
    align-items: center;
    justify-content: center;
}

.read-more a {
    font-size: 20px;
    font-weight: 400;
}

.count-down-row {
    display: grid;
    grid-template-columns: 80px 80px 80px 80px;
    gap: 15px;
}

.count-down-block {
    height: 81px;
    width: 80px;
    background: linear-gradient(91.6deg, #1450F5 0%, #3B6DF7 100%);
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25), 2px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    text-align: center;
}

.count-down-title {
    color: var(--text-color-primary);
    font-weight: 700;
    font-size: var(--text-l);
}

.count-down-text {
    font-weight: 700;
    font-size: 34px;
    color: #FFFFFF;
    margin-top: 14px;
}

.count-down-text-small {
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
}

.landing-button {
    max-width: 370px;
}

.lang-icons {
    display: grid;
    justify-content: center;
}

.lang-icons-row-1 {
    display: grid;
    grid-template-columns: 160px 160px 120px 170px;
    grid-gap: 30px;
    justify-content: center;
}

.lang-icons-row-2 {
    display: grid;
    grid-template-columns: 150px 140px 150px 150px;
    grid-gap: 20px;
    justify-content: center;
}

.lang-icons-row-3 {
    display: grid;
    grid-template-columns: 200px 200px;
    grid-gap: 20px;
    justify-content: center;
}

.lang-icon {
    cursor: pointer;
    display: grid;
    grid-template-columns: 65px auto;
    align-items: center;
}

.lang-flag {
    height: 50px;
    width: 50px;
    background-size: contain;
}

.modal-flag-row {
    display: grid;
    grid-template-columns: 70px auto;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
}

.link-icon {
    cursor: pointer;
    display: grid;
    grid-template-columns: 65px auto;
    align-items: center;
    text-decoration: none;
    color: black;
    padding-right: 24px;
}

.community-icons :not(:last-child) {
    border-right: 1px solid #9d9d9d6b;
}

.lang-flag {
    height: 64px;
    width: 60px;
    background-size: cover;
    border: none !important;
    background-repeat: no-repeat;
    background-position: center;
}

.lang-text {
    font-weight: 500;
    margin-left: 10px;
    color: var(--text-color-normal);
}

.community-icon-text {
    font-weight: 500;
    font-size: 24px;
    color: var(--text-color-normal);
}

.link-description {
    font-size: 20px;
}

.lang-icon:hover .lang-flag {
    //box-shadow: 0px 0px 9px 0px rgba(50, 50, 50, 0.66);
    border: 0px solid;
}

.lang-icon:hover .lang-text {
    font-weight: 700;
}

.lang-border {
    border-right: 1px solid silver;
}

.stat-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: 0px 1px 2px rgb(128 138 157 / 12%), 0px 8px 32px rgb(128 138 157 / 24%);
    border-radius: 16px;
    text-align: center;
    padding: 15px 60px;
}

.stat-info-header {
    font-weight: 500;
    font-size: var(--text-m);
}

.stat-info-sub-header {
    font-weight: 300;
    font-size: var(--text-s);
}

.features-header {
    font-weight: 700;
    font-size: var(--text-xxl);
    line-height: 120%;
    color: var(--text-color-normal);
}

.features-subheader {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--text-color-normal);
}

.feature-item {
    width: 336px;
    text-align: left;
    color: var(--text-color-normal);
}

.feature-item-header {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: var(--text-color-normal);
}

.feature-item-copy {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--text-color-normal);
}

.feature-item-img {
    width: 100%;
    height: 70px;
    background-position-x: center;
    background-repeat: no-repeat;
}

.feature-item-sustainable-yield {
    background-image: url("../imgs/sustainable_yield.png");
}

.feature-item-tokens-variety {
    background-image: url("../imgs/tokens_variety.png");
}

.feature-item-short-and-long {
    background-image: url("../imgs/short_and_long.png");
}

.feature-item-low-gas-fee {
    background-image: url("../imgs/low_gas_fee.png");
}

.feature-item-more-to-come {
    background-image: url("../imgs/more_to_come.png");
}

.feature-item-flexsec {
    background-image: url("../imgs/flex_sec.png");
}

.equal-width-grid {
    display: grid;
    grid-template-columns: 40% 50%;
    grid-gap: 50px;
}

.how-it-works-slide-row {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 50px;
}

@media screen and (max-width: 600px) {

    .lang-icons-row-1, .lang-icons-row-2, .lang-icons-row-3 {
        grid-template-rows: 65px;
        grid-template-columns: 150px 150px;
        grid-gap: 20px;
    }


    .lang-border {
        border-right: 0px solid silver;
    }

    .community-text {
        margin-left: var(--s);
        margin-right: var(--s);
        font-size: 16px;
        font-weight: 400;
    }

    .modal-lang {
        padding: 30px;
    }

    .community-icons {
        display: grid;
        grid-gap: 25px;
    }

    .community-icons :not(:last-child) {
        border-right: 0px solid #9d9d9d6b;
    }

    .stat-info {
        padding: 12px 40px;
    }

    .how-it-works-slide-row {
        display: grid;
        grid-template-columns: unset;
        grid-gap: 50px;
    }

    .equal-width-grid {
        display: grid;
        grid-template-columns: unset;
    }
}

.flag-uk {
    background-image: url("../imgs/flag-uk.png");
}

.flag-russia {
    background-image: url("../imgs/flag-russia.png");
}

.flag-thailand {
    background-image: url("../imgs/flag-thailand.png");
}

.flag-indonesia {
    background-image: url("../imgs/flag-indonesia.png");
}

.flag-china {
    background-image: url("../imgs/flag-china.png");
}

.flag-india {
    background-image: url("../imgs/flag-india.png");
}

.flag-spain {
    background-image: url("../imgs/flag-spain.png");
}

.flag-turkey {
    background-image: url("../imgs/flag-turkey.png");
}

.flag-south-korea {
    background-image: url("../imgs/flag-south-korea.png");
}

.flag-vietnam {
    background-image: url("../imgs/flag-vietnam.png");
}

.how-it-works-slides-container {
    height: 340px;
}

@media screen and (max-width: 600px) {
    .how-it-works-slides-container {
        height: 500px;
    }
}

.how-it-works-slide {
    width: 100%;
    height: auto;
    display: block;
}

.try-our-assistant {
    width: 560px;
    height: 333px;
    background-image: url("../imgs/tryOurAssistant.png");
}

.slide-header {
    font-weight: 500;
    font-size: var(--text-l);
    color: var(--text-color-normal);
}

.slider-pill {
    cursor: pointer;
    width: 40px;
    height: 12px;
    opacity: 0.5;
    border: 2px solid #0160EE;
    border-radius: 12px;
}

.slider-pill-rounded {
    cursor: pointer;
    width: 12px;
    height: 12px;
    opacity: 0.5;
    border: 2px solid #0160EE;
    border-radius: 50%;
}

.slider-pill-filled {
    background: #0160EE;
    opacity: 1;
}

.slider-nav-button {
    background: #0160EE;
    border-radius: 50%;
    height: 36px;
    width: 38px;
}

.slider-nav-button-left {
    left: 0;
    top: 50%;
    position: absolute;
}

.slider-nav-button-right {
    right: 0;
    top: 50%;
    position: absolute;
}

.slides-container {
    transition: ease-in-out;
    color: var(--text-color-normal);
}

.resources-card {
    background-color: var(--background-primary-color);
    box-shadow: 3px -1px 20px 0px #c1c1c1bf;
    border-radius: 0px 0px 4px 4px;
    height: 330px;
}

.resources-link {
    width: 260px;
    margin-right: var(--m);
    border-radius: var(--border-sharp);
    background-color: var(--background-primary-color);
    display: grid;
    grid-template-rows: 140px auto;
}

.resources-link-thumbnail {
    border-radius: var(--border-sharp) var(--border-sharp) 0 0;
}

.resources-header-1 {
    color: #8855FF;
    font-size: 20px;
}

.resources-header-2 {
    color: #FFCC00;
    font-size: 20px;
}

.resources-header-3 {
    color: #0941D7;
    font-size: 20px;
}

.resources-header-4 {
    color: #00CC88;
    font-size: 20px;
}

.resources-read-more-link {
    color: #5B86E5;
}

.community-panel {
    box-shadow: 0px 0px 40px 32px rgba(128, 138, 157, 0.05), 0px 1px 2px rgba(128, 138, 157, 0.12), 0px 8px 32px rgba(128, 138, 157, 0.24);
    color: var(--text-color-normal);
}