



.badge {
    display: inline-block;
    padding: 2px 8px;
    margin: 0 5px;
    background-color: #333333;
    border-radius: 12px;
    color: white;
    font-size: 12px;
    text-align: center;
}

.selected {
    background-color: var(--exotic-blue);   
}

.badge2 {
    display: inline-block;
    padding: 2px 5px;
    margin: 0 5px;
    background-color: #333333;
    border-radius: 12px;
    color: white;
    font-size: 12px;
    text-align: center;
}


.selected2 {
    background: linear-gradient(91.6deg, #00CC88 0%, #03A972 100%);
}