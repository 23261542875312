.header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background: var(--background-primary-color);
    min-height: 64px;
}

@media  (max-width: 600px) {
    .header {
        flex-direction: row;
        justify-content: space-around;
        height: 72px;
    }
    
}

.navbar {
    position: relative;
    background: var(--background-primary-color);
    box-shadow: 0 1px 2px rgba(128, 138, 157, 0.12), 0 8px 32px rgba(128, 138, 157, 0.24);
    z-index: 1;
}

.logo {
    display: block;
    cursor: pointer;
}

@media  (max-width: 600px) {
    .logo {
        display: flex;
        justify-content: center;
    }    
}

/* .connect-wallet-button {
     display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 60px;
    border-radius: var(--border-soft);
    color: #000;
    font-size: var(--text-m);
    line-height: 16px;
    text-transform: none;
    text-align: center;
    white-space: nowrap;
    background-color: initial;
    margin: 12px 0;
    min-height: 52px; 
} 

.connect-wallet-button:hover {
    background-color: #628d7c;
}
*/
.connect-wallet-button {
    background-color: var(--background-primary-color);
    color: var(--text-color-normal);
}    

@media  (max-width: 600px) {
    .connect-wallet-button {
        display: none;
    }    
}

.connect-wallet-button-mobile {
    display: none;
}

@media screen and (max-width: 600px) {
    .connect-wallet-button-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;
    }

    .connect-wallet-button-mobile:hover {
        background: linear-gradient(90deg, #FFFFFF 1.72%, #EBEBEB 75.94%);
        box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5)
    } 
    
    .connect-wallet-button-mobile:active {
        background: #EBEBEB;
        box-shadow: inset -4px -4px 8px #E2E2E2, inset 4px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

.tab-indicator {
    // background-color: #FB8F67;
    background-color: transparent;
    display: flex;
    justify-content: center;
}

.tab-indicator::before {
    content: '';
    background-color: #FB8F67;
    // height: 100%;
    width:65%;
}

.tab-panel {
    // padding: 0;
    min-height: 300px;
    // background: var(--background-secondary-color);
}

.tab-link {
    text-decoration-line: none;
    text-transform: none;
    color: var(--text-color-normal);
    margin: 16px 0;
    font-size: var(--text-m);
    font-weight: 300;
    line-height: 100%;
}

:global(.Mui-selected) .tab-link {
    font-weight: 500;
}

:global(.MuiTabs-indicator) {
    min-width: 40px;
}

// :global(.MuiButtonBase-root.Mui-disabled) {
//     background-color:rgb(202, 203, 203) !important;
//     border-radius: 8px;
//     cursor: pointer;
// }

.zero-width {
    display: none;
}

@media (min-width: 600px) {
    .navbar :global(.MuiTab-root) {
        min-width: 100px;
    }
}

.tabs {
    align-self: end;
    margin-bottom: 2px;
}

@media (max-width: 600px) {
    .tabs {
        display: none;
    }
}

.mobile-menu-item {
    display: none;
}

@media (max-width: 600px) {
    .mobile-menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.menu-button {
    all: initial;
  * {
    all: unset;
  }

  width: 24px;
  height: 24px;
  cursor: pointer;
}

.connected-chip-label {
    color: var(--text-color-normal);
}

.navbar-chip-border {
    border: 1px solid var(--border-color-normal);
}

.navbar-env-select {
    border: 1px solid var(--border-color-normal);
    background-color: var(--background-primary-color);
    color: var(--text-color-normal);
    border-radius: 10px;
    height: 50px;
    margin-top: 8px;
}

.theme-switch {
    justify-content: center;
    display: flex;
    height: 50px;
    margin-top: 8px;
}

// Injective Testnet temporary
.testnet-inj {
    padding: 14px 24px;
    border: 0 none;
    font-weight: 700;
    // letter-spacing: 1px;
    // text-transform: uppercase;
    border-radius: 10px;
    text-decoration: none;
}

.testnet-inj:focus, .testnet-inj:active:focus, .testnet-inj.active:focus {
    outline: 0 none;
}

.testnet-inj {
    background: #0099cc;
    color: #ffffff;
}

.testnet-inj:hover, .testnet-inj:focus, .testnet-inj:active, .testnet-inj.active, .open > .dropdown-toggle.testnet-inj {
    background: #33a6cc;
    cursor: default;
}

.testnet-inj:active, .testnet-inj.active {
    background: #007299;
    box-shadow: none;
}

.testnet-inj.howto {
	background: none;
	padding: 12px 22px;
    border: 2px solid var(--exotic-blue);
	color: var(--exotic-blue);
}

// .testnet-inj:active.howto:hover, .testnet-inj.howto:focus, .testnet-inj.howto:active, .testnet-inj.howto.active, .open > .dropdown-toggle.testnet-inj {
// 	color: #33a6cc;
// 	border-color: #33a6cc;
// }
// .testnet-inj.howto:active, .testnet-inj.howto.active {
// 	border-color: var(--exotic-blue);
// 	color: var(--exotic-blue);
// 	box-shadow: none;
// }

.testnet-inj.redeem {
	background: none;
	padding: 12px 22px;
    border: 2px solid var(--red-color);
	color: var(--red-color);
}

// .testnet-inj:active.redeem:hover, .testnet-inj.redeem:focus, .testnet-inj.redeem:active, .testnet-inj.redeem.active, .open > .dropdown-toggle.testnet-inj {
// 	color: rgb(241, 87, 87);
// 	border-color: rgb(241, 87, 87);
// }
// .testnet-inj.redeem:active, .testnet-inj.redeem.active {
// 	border-color: var(--red-color);
// 	color: var(--red-color);
// 	box-shadow: none;
// }

.testnet-inj a {
    color: var(--exotic-blue);
    text-decoration: none;
}

.testnet-inj.redeem a {
    color: var(--red-color);
    text-decoration: none;
}

.testnet-inj a:hover {
    color: #007299;
}

.testnet-inj.redeem a:hover {
    color: rgb(241, 87, 87);
}

@media (max-width: 600px) {
    .testnet-inj {
        display: none;
    }
}

.feedback-btn {
    position: fixed;
    // position-attachment: fixed;
    width: 45px;
    height: 100px;
    background: grey;// #009dbc;
    top: 45%;
    right: 0;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    box-shadow: 1px 1px 3px #000;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease-out;
    color: white;
}

.feedback-btn:hover {
	width: 50px;
}

.feedback-txt {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    position: absolute;
    right: -10px;
    top: 22px;
    color: #fff;
    transition: 0.2s ease-out;
}

.feedback-btn:hover .feedback-txt {
	right: -6px;
}

@media (max-width: 600px) {
    .feedback-btn {
        display: none;
    }
}
//