
.report-button-container {
    z-index: 1;
    position: fixed;
    right: 16px;
    bottom: 12px;
}

.report-button {
    display: flex;
    justify-content: center;
    background-color: #623A42;
    border-radius: 12px;
    color: #FFF;
    height: 56px;
    width: 156px;
    font-weight: bold;
    font-size: 16px;
    align-items: center;
}

.report-button:not([disabled]):hover {
    opacity: 0.7;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-top: 24px;
    background-color: #FFF;

    width: 368px;
}

.confirm-button {
    background: linear-gradient(180deg, #005DF2 0%, #0062FF 100%);
    box-shadow: 0px 2px 4px rgba(0, 98, 255, 0.3);
    border-radius: 12px;
    min-width: 320px;
    font-weight: bold;
    height: 50px;
    text-transform: none;
    margin-top: 40px;

}

.confirm-button:hover {
    // opacity: 0.7;
    background: linear-gradient(180deg, #004cc7 0%, #004cc7 100%);
}

.close {
    all: initial;
  * {
    all: unset;
  }

    float: right;
    margin-right: 16px;
    margin-bottom: 10px;
    cursor: pointer;
}

.close:hover {
    opacity: 0.7;
}

.text {
    font-size: 16px;
}

.row-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px 16px;
    width: 100%;
}

.label {
    display: inline-block;
    color: #232735;
    font-weight: bold;
    margin-bottom: 8px;
}

.header {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.input {
    margin-bottom: 40px;
}

.character-max {
    color: #76808F;
    font-size: 12px;
    margin-top: 8px;
}

.new-bug-Button {
    border: 2px solid #163E72;
    color: #163E72;
    font-weight: bold;
    background-color: #FFF;
    min-width: 100px;
    height: 50px;
}

.new-bug-Button:hover {
    background-color: #163E72;
    color: #FFF;
}

.continue-testing-button {
    min-width: 167px;
    background: linear-gradient(180deg, #005DF2 0%, #0062FF 100%);
    box-shadow: 0px 2px 4px rgba(0, 98, 255, 0.3);
    border-radius: 12px;
    font-weight: bold;
    height: 50px;
    text-transform: none;
}

.continue-testing-button:hover {
    background: linear-gradient(180deg, #004cc7 0%, #004cc7 100%);
}