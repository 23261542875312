
.assistant-background {
    padding-top: 40px;
    padding-bottom: 40px;
}

.assistant-container {
    background-color: var(--background-primary-color);
    border-radius: 16px;
    display: flex;
    flex: 1 1 auto;
    box-shadow: 0px 1px 2px rgba(128, 138, 157, 0.12), 0px 8px 32px rgba(128, 138, 157, 0.24);
    // flex-wrap: wrap;
}

.assistant-monkey-box {
    flex-basis: 120px;
    min-height: 120px;
    padding: 20px;
    background: #FFC542;
    border-radius: 16px 0px 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.assistant-exotic-box {
    padding: 18px 20px;
    flex-grow: 1;
}

.assistant-exotic-assistant {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #0062FF;
    margin-bottom: 10px;
}

.assistant-exotic-directions {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
}

.assistant-setup-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0px 16px 16px 0px;
    padding-top: 5px;
    padding-right: 5px;
    flex: 1 1 auto;
}

.assistant-setup-close {
    align-self: end
}

.assistant-next-button {
    padding: 14px 55px;
    margin-top: 25px;
    margin-right: 16px;
    margin-bottom: 16px;
    background: linear-gradient(180deg, #005DF2 0%, #0062FF 100%);
    border-radius: 12px;
    align-self: flex-end;
    white-space: nowrap;
}

.assistant-setup-close {
    align-self: end
}

.assistant-setup-button {
    padding: 14px 55px;
    width: 100%;
    background: linear-gradient(180deg, #005DF2 0%, #0062FF 100%);
    border-radius: 12px;
    align-self: flex-end;
    white-space: nowrap;
}

.assistant-setup-button:disabled, .assistant-next-button:disabled {
    opacity: 0.7;
}

.assistant-setup-button:enabled:hover, .assistant-next-button:enabled:hover {
    // opacity: 0.7;
    background: linear-gradient(180deg, #004cc7 0%, #004cc7 100%);
}

.stepper {
    max-width: 280px;
    padding-left: 0;
    margin-bottom: 20px;
    position:relative;
    right: -180px;
}

.step-container {
    font-size: 14px;
    display: flex;
    gap: 30px;
}

.step-container > *:first-child {
    //flex: 1;
    flex-basis: 320px;
}

.question {
    font-size: 14px;
    border-radius: 26px;
    color: var(--text-dark);
    background-color: var(--background-primary-color);
    border: 1px var(--text-dark) solid;
    // margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
}

.question:hover, .question.active {
    color: var(--exotic-blue);
    border-color: var(--exotic-blue);
}

.go-back {
    color: hsl(0, 0%, 51%);
    border-bottom: 1px solid hsl(0, 0%, 51%);
    padding-bottom: 5px;
}

.go-back:hover {
    cursor: pointer;
    color: hsl(0, 0%, 31%);
    border-color: hsl(0, 0%, 31%);
}

// button

.container {
    z-index: 10;
    position: fixed;
    right: 16px;
    top: 128px;
}

.button-width {
    width: 300px;
}

.title {
    color: var(--exotic-blue);
    font-size: 20px;
}

.step-title {
    margin-left: 0px;
    padding-left: 0px;
    margin-top: 5px;
    font-size: 18px;
    margin-bottom: 10px;
}

.modal-close {
    all: initial;
    
    * {
        all: unset;
    }
    
    position: absolute;
    cursor: pointer;
    top: -38px;
    right: -38px;
}

.modal-close2 {
    all: initial;

    * {
        all: unset;
    }

    position: absolute;
    cursor: pointer;
    top: -18px;
    right: -18px;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    padding: 24px;
 

    width: 660px;
}

.assistant-button-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 28px;    
}

.MuiSlider-markLabel {
    top: 26px;
    position: absolute;
    font-size: 0.875rem;
    transform: translateX(-50%);
    font-weight: 500;
    line-height: 1.1;
    font-family: 'Rubik', sans-serif;
    white-space: inherit;
    letter-spacing: 0em;
    text-align: center;
    width: 65px;
}