
.currency {
  color: var(--exotic-blue);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 200px;
//   line-height: 48px;
}

.currency-icon {
    width: 24px;
} 

.currency-large {
  font-size: 64px;
  width: auto;
}

.currency-large img {
  width: 65px;
  height: 65px;
}

.currency-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: var(--s);
}

.currencies-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.currency-container {
    width: 30px;
    height: 25px;
    display: grid;
    grid-template-columns: 15px 10px 15px;
    justify-content: space-between;
}

.currency-item-1 {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: span 2;
    z-index: 999;
}

.currency-item-2 {
    grid-column-start: 2;
    grid-row-start: 1;
    z-index: 99;
    grid-column-end: span 3;
    border-radius: 50%;
    filter: saturate(0) opacity(1) grayscale(1);
}