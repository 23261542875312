.pool-name {
    font-weight: 600;
}

.manage-liquidity-button {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    height: 56px;
    width: 56px;
    background-color: var(--blue-main-color);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
    margin-top: 30%;
    margin-left: -30%;
}

.duration-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: var(--xs);
    margin-top: var(--s);
}

.duration-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: calc(var(--xs) - 2px);
}

.duration-marker {
    width: var(--l);
    height: var(--s);
    background-color: #E8EDF6;
    border-radius: var(--s);
}

.duration-marker-small {
    width: var(--l);
    height: var(--xs);
    background-color: #E8EDF6;
    border-radius: var(--s);
}

.duration-marker-filled {
    background-color: var(--exo-primary);
}


.risk-marker {
    width: var(--l);
    height: var(--xs);
    background-color: #E8EDF6;
    border-radius: var(--s);
}

.risk-marker-filled-low {
    background-color: var(--low-color);
}

.risk-marker-filled-medium {
    background-color: var(--medium-color);
}

.risk-marker-filled-high {
    background-color: var(--high-color);
}

.tab-root {
    text-transform: none;
    width: 320px;

}

.tab-indicator {
    display: none;
}

.tabs {
    align-self: end;
    margin-bottom: 2px;
}

.tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 87px;
    padding: 16px;
    color:black;
    border-radius: var(--border-soft);
    width: 368px;
    font-size: var(--text-s);
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    background: var(--background-color-primary);
    box-shadow: 0px 0px 40px 32px rgba(128, 138, 157, 0.05), 0px 1px 2px rgba(128, 138, 157, 0.12), 0px 8px 32px rgba(128, 138, 157, 0.24);
}

.tab-subtext {
    font-size: var(--text-xs);
    color: #76808F;
    word-wrap: none;
}

.tab-selected {
    border: 2px solid #00CC88
}

.tab-selected-small {
    border: 2px solid black; // #00CC88;
    border-radius: var(--border-sharp);
}

.pool-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--xl);
    margin-bottom: var(--xs);
}

.pool-header {
    font-size: 12px;
    color: var(--exotic-gray);
}