

.background-primary {
    background-color: var(--background-primary-color);
}

.background-secondary {
    background-color: var(--background-secondary-color);
}

.background-tertiary {
    background-color: var(--background-tertiary-color);
}

.text-low-risk {
    color: var(--low-color);
}

.text-medium-risk {
    color: var(--medium-color);
}

.text-high-risk {
    color: var(--high-color);
}

.text-validation {
    color: var(--validation-color);
}

.border-low-risk {
    border-color: var(--low-color);
}

.border-medium-risk {
    border-color: var(--medium-color);
}

.border-high-risk {
    border-color: var(--high-color);
}

.hide {
    display: none;
}

.h-center {
    display: flex;
    justify-content: center;
}

.v-center {
    display: flex;
    align-items: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-height {
    height: 100vh;
}

.text-start {
    text-align: start;
}

.text-center {
    text-align: center;
}

.text-end {
    text-align: end;
}


.space-between {
    display: flex;
    justify-content: space-between;
}

.start {
    display: flex;
    justify-content: start;
}

.flex-1 {
    flex-grow: 1;
}

.flex-2 {
    flex-grow: 2;
}

.flex-3 {
    flex-grow: 3;
}

.flex-4 {
    flex-grow: 4;
}

.flex-5 {
    flex-grow: 5;
}

.no-wrap {
    white-space: nowrap;
}

.no-flex-wrap {
    flex-wrap: nowrap !important;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
/*    justify-content: space-between;
    align-items: center;
*/}

.col {
    display: flex;
    flex-direction: column;
}

.col1 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1
}

.col2 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2
}

.grid {
    display: grid;
}

/* Margins */

.margin-xxs-top {
    margin-top: var(--xxs);
}

.margin-xs-top {
    margin-top: var(--xs);
}

.margin-s-top {
    margin-top: var(--s);
}

.margin-m-top {
    margin-top: var(--m);
}

.margin-l-top {
    margin-top: var(--l);
}

.margin-xl-top {
    margin-top: var(--xl);
}

.margin-xxl-top {
    margin-top: var(--xxl);
}

.margin-xxs-bottom {
    margin-bottom: var(--xxs);
}

.margin-xs-bottom {
     margin-bottom: var(--xs);
 }

.margin-s-bottom {
     margin-bottom: var(--s);
 }

.margin-m-bottom {
     margin-bottom: var(--m);
 }

.margin-l-bottom {
     margin-bottom: var(--l);
 }

.margin-xl-bottom {
     margin-bottom: var(--xl);
 }

.margin-xxl-bottom {
     margin-bottom: var(--xxl);
 }

 .margin-xxs-left {
    margin-left: var(--xxs);
}

.margin-xs-left {
    margin-left: var(--xs);
}

.margin-s-left {
    margin-left: var(--s);
}

.margin-m-left {
    margin-left: var(--m);
}

.margin-l-left {
    margin-left: var(--l);
}

.margin-xl-left {
    margin-left: var(--xl);
}

.margin-xxl-left {
    margin-left: var(--xxl);
}

 .margin-xxs-right {
    margin-right: var(--xxs);
}

.margin-xs-right {
    margin-right: var(--xs);
}

.margin-s-right {
    margin-right: var(--s);
}

.margin-m-right {
    margin-right: var(--m);
}

.margin-l-right {
    margin-right: var(--l);
}

.margin-xl-right {
    margin-right: var(--xl);
}

.margin-xxl-right {
    margin-right: var(--xxl);
}

/* Padding */

.padding-xxs {
    padding: var(--xxs);
}

.padding-xs {
    padding: var(--xs);
}

.padding-s {
    padding: var(--s);
}

.padding-m {
    padding: var(--m);
}

.padding-l {
    padding: var(--l);
}

.padding-xl {
    padding: var(--xl);
}

.padding-xxl {
    padding: var(--xxl);
}

/* Padding top */

.padding-xxs-top {
    padding-top: var(--xxs);
}

.padding-xs-top {
    padding-top: var(--xs);
}

.padding-s-top {
    padding-top: var(--s);
}

.padding-m-top {
    padding-top: var(--m);
}

.padding-l-top {
    padding-top: var(--l);
}

.padding-xl-top {
    padding-top: var(--xl);
}

.padding-xxl-top {
    padding-top: var(--xxl);
}

/* Padding bottom */

.padding-xxs-bottom {
    padding-bottom: var(--xxs);
}

.padding-xs-bottom {
    padding-bottom: var(--xs);
}

.padding-s-bottom {
    padding-bottom: var(--s);
}

.padding-m-bottom {
    padding-bottom: var(--m);
}

.padding-l-bottom {
    padding-bottom: var(--l);
}

.padding-xl-bottom {
    padding-bottom: var(--xl);
}

.padding-xxl-bottom {
    padding-bottom: var(--xxl);
}

/* Padding left */

.padding-xxs-left {
    padding-left: var(--xxs);
}

.padding-xs-left {
    padding-left: var(--xs);
}

.padding-s-left {
    padding-left: var(--s);
}

.padding-m-left {
    padding-left: var(--m);
}

.padding-l-left {
    padding-left: var(--l);
}

.padding-xl-left {
    padding-left: var(--xl);
}

.padding-xxl-left {
    padding-left: var(--xxl);
}

/* Padding right */

.padding-xxs-right {
    padding-right: var(--xxs);
}

.padding-xs-right {
    padding-right: var(--xs);
}

.padding-s-right {
    padding-right: var(--s);
}

.padding-m-right {
    padding-right: var(--m);
}

.padding-l-right {
    padding-right: var(--l);
}

.padding-xl-right {
    padding-right: var(--xl);
}

.padding-xxl-right {
    padding-right: var(--xxl);
}

.show-only-mobile {
    display: flex;
}

@media (min-width: 950px) {
    .show-only-mobile {
        display: none;
    }
}

/* Gap */

.gap-xxs {
    gap: var(--xxs);
}

.gap-xs {
    gap: var(--xs);
}

.gap-s {
    gap: var(--s);
}

.gap-m {
    gap: var(--m);
}

.gap-l {
    gap: var(--l);
}

.gap-xl {
    gap: var(--xl);
}

.gap-xxl {
    gap: var(--xxl);
}

/* Text Sizes */
.text-xxxl {
    font-size: var(--text-xxxl);
}

.text-xxl {
    font-size: var(--text-xxl);
}

.text-xl {
    font-size: var(--text-xl);
}

.text-l {
    font-size: var(--text-l);
}

.text-m {
    font-size: var(--text-m);
}

.text-s {
    font-size: var(--text-s);
}

.text-xs {
    font-size: var(--text-xs);
}

.text-xxs {
    font-size: var(--text-xxs);
}

/* Text Colors */

.text-dark {
    color: var(--text-dark)
}

.text-light {
    color: var(--text-light)
}

.text-very-light {
    color: var(--text-very-light)
}

/* Font Weight */

.boldness-300 {
    font-weight: 300;
}

.boldness-400 {
    font-weight: 400;
}

.boldness-500 {
    font-weight: 500;
}

.boldness-600 {
    font-weight: 600;
}

.boldness-700 {
    font-weight: 700;
}



.align-start {
    justify-self: start;
    align-self: start;
}

.align-center {
    justify-self: center;
    align-self: center;
}

.align-end {
    justify-self: end;
    align-self: end;
}

input.input-error, textarea.input-error {
    border: 2px var(--high-color) solid !important;
}

.hide {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.grid-column-1 {
    grid-column: 1;
}

.grid-column-2 {
    grid-column: 2;
}

.grid-column-3 {
    grid-column: 3;
}

.grid-column-4 {
    grid-column: 4;
}

.grid-column-5 {
    grid-column: 5;
}

.grid-column-6 {
    grid-column: 6;
}

.grid-column-7 {
    grid-column: 7;
}

.grid-column-8 {
    grid-column: 8;
}

.full-width {
    width: 100%;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.text-scenario-a {
    font-size: var(--text-m);
    color: #8884d8;
}

.text-scenario-b {
    font-size: var(--text-m);
    color: #82ca9d;
}

.font-inter {
    font-family: 'Inter', sans-serif;
}

.hide-lg{

}

.hide-xs {
    display: none !important;
}

@media screen and (min-width: 640px) {
    .hide-lg {
        display: none;
    }

    .hide-xs {
        display: flex !important;
    }
}

.remove-box-shadow {
    box-shadow: none !important;
}

.horizontal-scroll {
    max-width: 100%;
    overflow-x: auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        background: transparent;  
        -webkit-appearance: none;
        width: 0;
        height: 0;
    }  
}

.horizontal-scroll-item {
    white-space: nowrap;
}

.new-exo-color {
    color: var(--exo-primary);
}

