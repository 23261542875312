/* Fonts */


/* General rules and helpers */

.section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.content {
    width: 100%;
    max-width: 1200px;
    position: relative;
}

@media (max-width: 1240px) {
    .content {
        margin: 0 20px;
    }
}

/* Page */


.landing-page {
    background-color: #E3EEFF;
}


/* landing section */

.landing.section {
    /* background-color: #163e72; */
    background: linear-gradient(0.25turn, #163E72, #0E2645);
    color: white;
}

.landing .content {
    padding-top: 20px;
    /* padding-bottom: 30px; */
}

.landing .greeting-container {
    margin-top: 40px;
    /* margin-bottom: 40px; */
    display: flex;
    flex-wrap: wrap;
}

.landing .greeting-container .greeting-container-content {
    max-width: 540px;
    margin-bottom: 50px;
}

.landing .greeting-container p {
    margin-top: 20px;
    margin-bottom: 20px;
}

.welcome-text {
    font-size: 64px;
    line-height: 80px;
    font-weight: 700;
}

.welcome-sub-text {
    font-size: 20px;
}

.landing #pin-img {
    margin-top: 10px;
}

.logo-div {
    display: flex;
    justify-content: space-between;
}
.enter-btn1 {
    height: 50px;
    background-color: #40df9f !important;
    border-radius: 15px !important;
    border: none !important;
    color: white !important;
    font-size: medium !important;
    box-shadow: none !important;
    text-transform: none !important;
}

.enter-btn1 :hover {
    color: #3c52b2;
}

.enter-btn2 {
    height: 40px;
    background-color: transparent !important;
    color: #40df9f !important;
    border: 1px solid #40df9f !important;
    border-radius: 15px !important;
    box-shadow: none !important;
    text-transform: none !important;
}

.enter-btn2 :hover {
    color: #fff;
}

.splash {
    width: 650px;
    max-width: 100%;
}

/* feature section */

.feature-section {
    text-align: center;
    margin: auto;
    padding-top: 70px;
}

.feature-section-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(min-content, max-content);
    column-gap: 20px;
    row-gap: 60px;
    padding-top: 20px;
}

.feature-section-item {
    display: flex;
    flex-direction: column;
}

.feature-circle {
    background-color: #fff;
    height: 150px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: -75px;
    z-index: 100;
}

.feature-box {
    margin: auto;
    border-radius: 15px;
    padding: 65px 25px 35px 25px;
    flex-grow: 1;
    align-items: center;
    grid-template-rows: auto auto;
}

.feature-box .title {
    margin-top: 30px;
    margin-bottom: 25px;
}

.feature-box .text {
    font-size: 20px;
    line-height: 32px;
    margin: 0 0;
}

.feature1 {
    border: 5px solid #ff565e;
}

.feature2 {
    border: 5px solid #ffc542;
}

.feature3 {
    border: 5px solid #3ed598;
}

.feature1-box {
    background-color: #ff565e;
}

.feature2-box {
    background-color: #ffc542;
}

.feature3-box {
    background-color: #3ed598;
}

/* how to work section */

.how-work-section {
    justify-content: center;
    text-align: center;
    margin: auto;
    padding-top: 50px;
}

.how-work-items {
    width: 100%;
}

.how-work-item {
    display: grid;
    align-items: center;
    justify-items: center;
}

.how-work-item:nth-child(even) {
    justify-content: flex-start;
    grid-template-columns: 2fr 1fr 4fr 1fr;
}

.how-work-item:nth-child(odd) {
    justify-content: flex-end;
    grid-template-columns: 1fr 4fr 1fr 2fr;
}

.how-work-item:not(:first-child) {
    margin-top: 30px;
}

.name-step {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    text-align: left;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
}

.name-step .title {
    display: flex;
}

.name-step .title-text {
    font-size: 25px;
    display: flex;
    align-items: center;
    flex-shrink: 1;
}

.num-box {
    background-color: #0062ff;
    height: 75px;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 20px;
    flex-shrink: 0;
}

.pic-box {
    display: flex;
    height: 234px;
    width: 234px;
    border-radius: 20px;
    background-color: white;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.pic-box img {
    border-radius: 20px;
}

/* faq section */

.faq-section {
    justify-content: center;
    margin: auto;
    padding-top: 50px;
    text-align: center;
}

.faq-heading {
    font-size: 15px;
    text-align: start;
}

.accordion {
    border-radius: 20px !important;
    border: none;
    margin-top: 20px;
}

/* community section */

.community-section {
    padding-bottom: 150px;
    justify-content: center;
    margin: auto;
    padding-top: 50px;
    text-align: center;
}

.community-section-items-holder {
    margin-top: 40px;
    display: grid;
    align-items: center;
    justify-content: center;
}

.community-section-items {
    width: 50vw;
    display: grid;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    grid-template-columns: repeat(5, max-content);
}

.community-section-items .spacer {
    display: none;
}

.icon-box {
    background-color: #0062ff;
    height: 75px;
    border-radius: 30%;
    -moz-border-radius: 30%;
    -webkit-border-radius: 30%;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 0 10px;
}



.social {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-top: 40px;
}

/* footer */

.footer {
    background-color: white;
}

.footer .content {
    text-align: left;
    padding-top: 60px;
}

.footer .copyright {
    width: 100%;
    text-align: center;
    margin-top: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 16px;
    color: #76808F;    
    border-top: 1px solid #E8EDF6;
}

.footer-title {
    color: #00459d;
    font-size: 20px;
}

.footer .logo-tagline {
    max-width: 270px;
}


@media (max-width: 610px) {
    .greeting-container .welcome-text {
        font-size: 48px;
        line-height: 64px;
    }
    .greeting-container .welcome-sub-text {
        font-size: 20px;
        line-height: 32px;
    }
    .feature-circle {
        height: 80px;
        width: 80px;
    }

    .feature-section-items {
        grid-template-columns: repeat(1, 1fr);
    }

    .how-work-item, .how-work-item:nth-child(odd), .how-work-item:nth-child(even) {
        grid-template-columns: 0fr 1fr 0fr 0fr;
    }

    .how-work-item .pic-box {
        display: none;
    }

    .icon-box {
        margin: 10px 20px;
    }
}



/* MaterialUI overrides */

.MuiAccordion-root:before {
    height: 0px !important;
}