
.leaderboard-headers {
    display: grid;
    grid-template-columns: 64px 416px 196px 80px 80px;
    gap: var(--xl);
    width: 100%;
}

// .leaderboard-header {
//     cursor: pointer;
// }

.leaderboard-row {
    display:grid;
    grid-template-columns: 64px 416px 196px 80px 80px;
    gap: var(--xl);
    width: 100%;
    height: 48px;
}

.leaderboard-column1 {
    grid-column: 1;
}

.leaderboard-column2 {
    grid-column: 2;
}

.leaderboard-column3 {
    grid-column: 3;
}

.leaderboard-column4 {
    grid-column: 4;
}

.leaderboard-column5 {
    grid-column: 5;
}

.performance-column {
    color: var(--carribean-green-color);
}

.performance-negative-column {
    color: var(--red-color);
}

.first-place {
    background: linear-gradient(91.6deg, var(--jasmine) 0%, #F2F5F9 100%);
}

.second-place {
    background: linear-gradient(91.6deg, #E8EDF6 0%, #F2F5F9 100%);
}

.third-place {
    background: linear-gradient(91.6deg, var(--corat) 0%, #F2F5F9 100%);
}

.you {
    border: 1px solid #1450F5;
}



.you-tag {
    border: 1px solid #1450F5;
}
