.select {
    min-width: 200px;
}

.filter-button {
    padding: var(--s);
    margin: 1px; // Offset for extra border pixel
}

.filter-button-active {
    color: var(--exotic-blue);
}

.filter-button-active .filter-button {
    border: 2px solid var(--exotic-blue);
    box-shadow: none;
    margin: 0px; // Offset for extra border pixel
}

.filter-button-market-up-active {
    color: var(--green-color);
}

.filter-button-market-up-active .filter-button {
    border: 2px solid  var(--green-color);
    box-shadow: none;
    margin: 0px; // Offset for extra border pixel
}

.filter-button-market-down-active {
    color: var(--red-color);
}

.filter-button-market-down-active .filter-button {
    border: 2px solid var(--red-color);
    box-shadow: none;
    margin: 0px; // Offset for extra border pixel
}

.filter-button-market-stable-active {
    color: var(--sunglow-color);
}

.filter-button-market-stable-active .filter-button {
    border: 2px solid var(--sunglow-color);
    box-shadow: none;
    margin: 0px; // Offset for extra border pixel
}