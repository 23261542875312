
.disclaimer-icon-background {
    position: absolute;
    margin-left: -16px;
    margin-top: -16px;
    border-radius: var(--border-sharp) 0 0 0;
}

.disclaimer-icon {
    position: absolute;
}

.progress-bar {
    all: unset;
    background: #5D5FEF;
    height: var(--s);
    margin-bottom: -16px;
    border-radius: var(--border-sharp);
    
}

/* .progress-bar-complete {
    border-radius: 0 0 var(--border-sharp) var(--border-sharp);
} */

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-top: 24px;
    background-color: #FFF;
    width: 560px;
}

@media screen and (max-width: 600px) {
    .modal {
        max-width: 344px;
    }
}
// positioning of the steps
.step-1 {
    top: 20%;
    left: 67%;
}

.step-2 {
    top: 40%;
    left: 67%;
}

.step-3 {
    top: 40%;
    left: 67%;
}

.step-4 {
    top: 30%;
    left: 50%;
}

.step-5 {
    top: 30%;
    left: 50%;
}

.skip-button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7D7C84;
    cursor: pointer;
    font-size: var(--text-xs);
}