
// .cards {
//     display: grid;
//     grid-template-columns: auto auto auto;
//     grid-gap: 40px 16px;
//     background-color: var(--background-primary-color);
//     padding-top: 0px;
//     padding-bottom: 80px;
// }

@media screen and (max-width: 600px) {
  .cards {
      grid-template-columns: 344px;
      row-gap: 24px;
      padding-left: 16px;
      padding-right: 16px;
  }
}

.cards {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0px;
    column-gap: var(--m);
    row-gap: var(--l);
}

.header-background {
  position: relative;
  background: linear-gradient(79.75deg, #323F9D 0%, #1B2572 57.66%);
  background-repeat: no-repeat;
  background-size: 100% 435px;
}