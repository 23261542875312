.search-input {
    display: inline-flex;
    width: fit-content;
    height: 52px;
    /*box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4);*/
    border-radius: var(--border-soft);
    font-size: var(--text-m);
}

.search-input input {
    height: 100%;
    border: none;
    border: 1px solid #E8EDF6;
    border-right: none;
    border-radius: var(--border-soft) 0 0 var(--border-soft);
    line-height: 1;
    padding: var(--m);

    min-width: 420px; // Changed
    font-weight: 300; // Added
}

.search-input button {
    height: 100%;
    border-left: none;
    border-radius: 0 var(--border-soft) var(--border-soft) 0;
    text-align: center;
    padding: var(--xxs) var(--l);
    transition: all 0.3s ease;
    min-height: 50px;

    color: var(--text-dark); // Added
    background-color: var(--background-primary-color); // Changed
    border: 1px solid #E8EDF6; // Changed
}

.search-input button:hover {
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.search-input button:active {
    box-shadow: inset -4px -4px 8px #474747, inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.search-input input::placeholder {
    color: var(--text-very-light);
}

.search-input-button {
    gap: 20px;
}